import React from 'react';
import { useContext, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/esm/Container";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from './formik/TextInput';
import { API_URL } from '../../constants';

export const CreateProject = ({updateState}) => {
    let {authTokens} = useContext(AuthContext);

    let postConsult = async(data, callback, formReset) => {
        let response = await fetch(`${API_URL}/api/landscms/consults/`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(data)
        })

        let response_data = await response.json();

        if(response.status === 200) {
            console.log(response_data)
            callback(false);
            formReset();
            updateState(true);
        } else if (response.statusText === 'Unauthorized') {
            alert("Not Authorized to create contacts");
        } else {
            alert("Could not add a new contact.")
        }
    }

    return (
        <Container>
            <h4>Start New Consultation</h4>
            <Formik
            initialValues={{
                file_name: '',
                authority_ref_no: '',
                revision_num: 0
            }}
            validationSchema={Yup.object({
                file_name: Yup.string().required("Required Field").test({
                    message: 'File name must be less than 50 characters',
                    test: (value) => value == null || value.length <= 50,
                }),
                authority_ref_no: Yup.string().required("Required Field").test({
                    message: 'Reference number must be less than 50 characters',
                    test: (value) => value == null || value.length <= 50,
                }),
                revision_num: Yup.number().integer()
            })}
            onSubmit={(values, { setSubmitting, resetForm }) =>{
                setSubmitting(true);
                postConsult(values, setSubmitting, resetForm);
            }}
            >
                <Form>
                    <div className='position-relative new-project-form' controlid='projectForm'>
                        <FormikTextInput 
                            label="File Name"
                            name="file_name"
                            type="text"
                            className="w-75 mx-auto my-1"
                        />
                        <FormikTextInput 
                            label="Reference Number"
                            name="authority_ref_no"
                            type="text"
                            className="w-75 mx-auto my-1"
                        />
                        <FormikTextInput 
                            label="Revision"
                            name="revision_num"
                            type="number"
                            className="w-75 mx-auto my-1"
                        />
                    </div>
                    <Container className="mx-auto d-flex justify-content-center">
                        <Button className="my-3 mx-2" type="submit">Save</Button>
                        <Button className="my-3 mx-2">Cancel</Button>
                    </Container>
                </Form>
            </Formik>
        </Container>
    )
}
