import React, {useState, useEffect, useContext} from 'react';
import AuthContext from '../context/AuthContext';
import DocumentType from './forms/DocumentType';
import axios from 'axios';
import { API_URL } from '../constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faMagnifyingGlassArrowRight, faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';

const ConsultationFile = ({doc, docDeleted, ...props}) => {
  const {authTokens} = useContext(AuthContext)
  const [isDeleted, setDeleted] = useState(false);

    useEffect(() => {
      if (isDeleted) {
        docDeleted(doc.document_id)
      }
    },[isDeleted])

    const deleteDocument = () => {
        let fetchUrl = `${API_URL}/api/landscms/consult-documents/${doc.document_id}/`;
        axios.delete(fetchUrl, {
          headers: {
            'Authorization':'Bearer ' + String(authTokens.access)
          }
        }).then(setDeleted(true))
    }

  return (
    <div key={doc.document_id} className="bg-light border" hidden={isDeleted}>
        <span style={{fontWeight: "bold"}}>{doc.f_name}</span>
        {
          doc.doc_geojson.length > 0 ?
          <FontAwesomeIcon 
          className="mx-1" style= {{ color: 'blue'}}
          icon={faLocationCrosshairs}
          title="Zoom to Layer"
          onClick={() => props.setSelectedDocId(doc.document_id)}
          /> : null
        }
        <FontAwesomeIcon 
        className="mx-2" style= {{ color: 'blue'}}
        icon={faMagnifyingGlassArrowRight}
        title="View or Download Document"
        onClick={() => window.open(doc.f_path, '_blank').focus()}
        />
        <FontAwesomeIcon
        className="mx-1" 
        style= {{ color: 'red'}}
        icon={faClose}
        onClick={deleteDocument}
        title="Delete Document"
        />    
        <br></br>
        <DocumentType doc={doc}/> 
    </div>
  )
}

export default ConsultationFile