import React, {useEffect, useState} from 'react';
import MapZoom from './leaflet/MapZoom';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    LayersControl
  } from 'react-leaflet';
  import L, { latLngBounds } from 'leaflet';
  
  import { BasemapLayer, FeatureLayer } from "react-esri-leaflet";
  
  import 'leaflet/dist/leaflet.css';

const LeafletMap = ({...props}) => {
  const defaultBounds = latLngBounds([50, -121.51177],[60, -119]);

  return (
    <div>           
    <MapContainer className='leaflet-div' bounds={defaultBounds} scrollWheelZoom={true}>
      <LayersControl>
        <LayersControl.BaseLayer name="ESRI Imagery">
            <BasemapLayer name="Imagery" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name="Open Street Maps">
            <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        </LayersControl.BaseLayer>
        {props.children}
      </LayersControl>
    </MapContainer>
    </div>
  )
}

export default LeafletMap