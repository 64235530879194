import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { API_URL } from '../constants';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import UpdateConsultation from '../components/forms/UpdateConsultation';

const ConsultationEditing = () => {
    const {referral_id} = useParams();
    const history = useHistory();
    let [initialEditingTime, setInitialEditingTime] = useState(1000 * 60 * 5)
    let {authTokens, logoutUser} = useContext(AuthContext);
    let [consult, setConsult] = useState([]);
    let [editingTime, setEditingTime] = useState(initialEditingTime);
    let [editingSeconds, setEditingSeconds] = useState(initialEditingTime/1000)
    let [editingLocked, setEditingLocked] = useState(true);

    useEffect(() => {
        getConsult(referral_id);
        setEditingLocked(true);
        putEditing(referral_id, editingLocked);
    }, [])

    useEffect(() => {
        let timerInterval = setInterval(() => {
            if(editingTime > 0) {
                setEditingTime(editingTime - 1000)
                setEditingSeconds(editingTime/1000)
            } else {
                putEditing(referral_id, false)
            }
        }, 1000)
        return () => {clearInterval(timerInterval)}
    }, [editingTime])

    let updateEditingTime = () => {
        setEditingTime(initialEditingTime);
        putEditing(referral_id, true);
    }

    let getConsult = async(referralId) => {
        let fetch_url = `${API_URL}/api/landscms/consults/${referralId}/`;
        let response = await fetch(fetch_url, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })
        let data = await response.json();
        if(response.status === 200) {
            setConsult(data);
        } else if (response.statusText === 'Unauthorized') {
            logoutUser();
            history.push('/login')
        } else {
            console.log(response)
        }
    }

    let putEditing = async(referral_id, editingLocked) => {
        let fetch_url = `${API_URL}/api/landscms/consults/${referral_id}/?editing_lock=${editingLocked}`
        let response = await fetch(fetch_url, {
            method: 'PUT',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })
        if (response.statusText === 'Unauthorized') {
            console.log("You do not have permissions to edit this consult.")
        } else if (response.status === 403) {
            console.log("You do not have permissions to edit this consultation");
            history.push(`/consultation/${referral_id}`);
        }
    }


    return (
        <div>
            {
                editingTime > 0 ?
                <UpdateConsultation consult={consult} childToParent={getConsult} putEditing={putEditing} editingSeconds={editingSeconds} updateEditingTime={updateEditingTime}/>
                : <div><h2>Editing Session Has Expired</h2><Button onClick={() => {history.goBack()}}>Go Back</Button></div>
            }
        </div>
    )
}

export default ConsultationEditing