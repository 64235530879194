import React from 'react';
import { useField } from 'formik';
import { Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const Checkbox = ({ label, ...props }) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
        <Row className={props.className}>
            <div className='d-inline-flex justify-content-center'>
            <label htmlFor={props.id || props.name}>{label}</label>
            { props.info ? 
            <div><FontAwesomeIcon className="mx-2" style={{color: "blue"}} icon={faCircleInfo} title={props.info}/></div> 
            : 
            <div></div>
            }
            </div>
            <input type="checkbox" {...field} {...props} />
            {props.children}
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </Row>
    )
}

export default Checkbox