import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { API_URL } from '../constants';
import AuthContext from '../context/AuthContext';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ConsultationFile from './ConsultationFile';

const FileUpload = ({file, callback, ...props}) => {
  const [progress, setProgress] = useState(0);
  const [barVariant, setBarVariant] = useState('success');
  const [hidden, setHidden] = useState(false);
  const [fileName, setFileName] = useState('');
  const [newDoc, setNewDoc] = useState([]);
  let {authTokens} = useContext(AuthContext);

  useEffect(() => {
    postFile(file);
    setFileName(file.name);
  }, [])

  useEffect(() => {
    if (progress === 100) {
        setInterval(() => {
            setHidden(true);
        }, 5000)
    if (progress === 0) {
        setBarVariant("warning");
        setProgress(0);
        setHidden(false);
    }
    }
  },[progress])

  const postFile = async(file) => {
      let formData = new FormData();
      formData.append("f_path", file);
      formData.append("referral_id", file.referral_id);
      if ( props.acceptedFileFormats.includes( "." + file.name.split('.').pop() ) ) {
          let fetchUrl = `${API_URL}/api/landscms/consult-documents/`;
          axios.post(fetchUrl, formData, {
              headers: {
                  "Content-Type": "multipart/form-data",
                  'Authorization':'Bearer ' + String(authTokens.access)
              },
              onUploadProgress: function (p) {
                  let progress = Math.round((p.loaded / p.total) * 100) - 1;
                  setProgress(progress);
              }
          }).then(function (response) {
              setNewDoc(response.data);
              callback(response.data);
              setProgress(100);
          }).catch(function (error) {
              console.log(error);
              setProgress(0)
          })
      } else {
          alert(`${file.name} cannot be uploaded because it is not an acceptable file type (only ${props.acceptedFileFormats.join(',')} are accepted).`)
          setProgress(100);
          setBarVariant('danger');
      }
  }

  return (
    <div key={props.key} className='file-div'>
        <div hidden={hidden}>
            <p>{fileName}</p>
            <ProgressBar variant={barVariant} now={progress} label={progress + '%'} />
        </div>
        {hidden && newDoc ? <ConsultationFile key={newDoc.document_id} doc={newDoc} docDeleted={props.docDeleted} setSelectedDocId={props.setSelectedDocId} /> : <div></div>}
    </div>
  )
}

export default FileUpload