import React, {useEffect, useState} from "react";
import { Editor, EditorState, convertToRaw, convertFromHTML, ContentState} from "draft-js";
import draftToHtml from 'draftjs-to-html';
import "draft-js/dist/Draft.css";
import { useFormikContext } from "formik";

export default function TextEditor({label, initVal, ...props}) {
  const formikProps = useFormikContext();
  let [editorState, setEditorState] = useState(() =>
      EditorState.createWithContent(
          ContentState.createFromText(formikProps.getFieldProps("description").value)
      )
  );

  useEffect(() => {
      if (initVal) {
          let blks = convertFromHTML(initVal);
          let state = ContentState.createFromBlockArray(
              blks.contentBlocks, blks.entityMap,
          )
          setEditorState(
              EditorState.createWithContent(
                  state
              )
          )
      }
  }, [initVal])

  useEffect(() => {
    let input = convertToRaw(editorState.getCurrentContent());
    formikProps.setFieldValue("description", draftToHtml(input));
  }, [editorState])

  const editor = React.useRef(null);
  function focusEditor() {
    editor.current.focus();
  }

  return (
    <div
      style={{ border: "1px solid black", minHeight: "6em", cursor: "text" }}
      onClick={focusEditor}
      className={props.className}
    >
      <Editor
        name="description"
        ref={editor}
        editorState={editorState}
        onChange={setEditorState}
        toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "remove",
              "history",
            ],
          }}
      />        
    </div>
  );
}