import React, {useContext, useState, useEffect, useCallback} from "react";
import {useHistory} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AuthContext from '../../context/AuthContext';
import { API_URL } from "../../constants";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faClose, faPen } from '@fortawesome/free-solid-svg-icons';

import ConsultationModal from "../modals/ConsultationModal";

const ConsultationCards = ({consults}) => {
    let [consultModal, setConsultModal] = useState([]);
    let [showModal, setShowModal] = useState(false);
    let {authTokens} = useContext(AuthContext);
    const history = useHistory();

    let deleteConsult = async(referral_id) => {
        let fetch_url = `${API_URL}/api/landscms/consults/${referral_id}/`
        let response = await fetch(fetch_url, {
            method: 'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            body: {
                'referral_id': referral_id
            }
        })
        if(response.status === 200) {
            setShowModal(false);
        } else if (response.statusText === 'Unauthorized') {
            alert("You do not have permissions to delete this consult.")
        } else {
            console.log(response)
        }
    }

    let getConsult = async(consult_id) => {

        let fetch_url = `${API_URL}/api/landscms/consults/${consult_id}/`
        let response = await fetch(fetch_url, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })
        let data = await response.json();
        if(response.status === 200) {
            return data;
        } else if (response.statusText === 'Unauthorized') {
            alert("You do not have permissions to edit this consult.")
        } else {
            console.log(response)
        }
    }

    let clearModal = () => {
        setConsultModal([])
    }

    let getConsultationModal = (referral_id) => {
        setConsultModal(<ConsultationModal referral_id={referral_id}  getConsult={getConsult} showModal={true}></ConsultationModal>)
    }

  return (
    <div>
        <div>{consultModal}</div>
        <Row className="mx-auto">
        {consults.map((consult) => (
        <Col key={consult.referral_id} className="m-2">
            <Card value={consult.referral_id} style={{width: '300px'}}>
                <Card.Title className={consult.status.status === 'Draft' ? "text-danger" : "text-dark"}>{consult.status.status}</Card.Title>
                <Card.Title>{consult.file_name}</Card.Title>
                <Card.Text className="mb-1">
                    Reference #: <b>{consult.authority_ref_no}</b> Rev: <b>{consult.revision_num}</b>
                    <br></br>
                    Created: <b>{ new Date(consult.created).toLocaleDateString() }</b> Edited: <b>{ new Date(consult.edited).toLocaleDateString() }</b>
                </Card.Text>
                <CardGroup className="mx-auto justify-content-center mb-2">
                    <FontAwesomeIcon className="mx-1" style= {{ color: 'blue'}}
                    icon={faCircleInfo}
                    onClick={() => history.push('/consultation/' + consult.referral_id)}
                    />
                    {
                        consult.submitted || consult.editing_locked ? <div></div>
                        :
                        <FontAwesomeIcon className="mx-1" style= {{ color: 'green'}}
                        icon={faPen} onClick={() => history.push('/consultation-edit/' + consult.referral_id)}
                        />
                    }
                    {
                        consult.submitted || consult.editing_locked ? '' 
                        :
                        <FontAwesomeIcon className="mx-1" style= {{ color: 'red'}}
                        icon={faClose} onClick={() => deleteConsult(consult.referral_id)}
                        />
                    }
                </CardGroup>
                <Card.Footer>Owner: {consult.creator.username}</Card.Footer>
            </Card>
        </Col>
        ))}

        </Row>
    </div>
  )
}

export default ConsultationCards