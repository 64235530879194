import React from 'react';
import { useField } from 'formik';
import Row from 'react-bootstrap/Row';
import Control from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const FormikTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
      <Row className={props.className}>
        <div className='d-inline-flex justify-content-center'>
          <Control.Label htmlFor={props.id || props.name}>{label}</Control.Label>
          { props.info ? 
          <div><FontAwesomeIcon className="mx-2" style={{color: "blue"}} icon={faCircleInfo} title={props.info}/></div> 
          : 
          <div></div>
          }
        </div>
        <input className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="formik-error">{meta.error}</div>
        ) : null}
      </Row>
    );
  };
  

  export default FormikTextInput