import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faClose, faPen } from '@fortawesome/free-solid-svg-icons';

const ContactCards = ({contacts, ...props}) => {
    const {user} = useContext(AuthContext);

  return (
    <div>
    <Row className="mx-auto">
        {contacts.map((contact) => (
        <Col key={contact.contact_id} className="m-2">
            <Card value={contact.contact_id} style= {{ width: '250px'}}>
                <Card.Body>
                    <Card.Title>{contact.l_name}, {contact.f_name}</Card.Title>
                    { contact.org ?
                        <Card.Subtitle className="my-1">{contact.org.name}</Card.Subtitle>
                        : <div></div>
                    }
                    <Card.Subtitle>{contact.title}</Card.Subtitle>
                    <Card.Text className="mt-1">
                        {contact.address} 
                        <br></br>
                        {contact.city}, {contact.prov} {contact.country}
                    </Card.Text>
                    <CardGroup className="mx-auto justify-content-center">
                        <FontAwesomeIcon className="mx-1" style= {{ color: 'green'}}
                        icon={faPen} onClick={() => props.getContact(contact.contact_id)}
                        title="Update Contact's Details"
                        />
                        {contact.user || 
                            contact.creator.username !== user.username  ? 
                        <div></div>
                        :
                        <FontAwesomeIcon className="mx-1" style= {{ color: 'red'}}
                        icon={faClose} onClick={() => props.deleteContact(contact.contact_id)}
                        title="Delete Contact"
                        />
                        }
                    </CardGroup>
                </Card.Body>
            </Card>
        </Col>
        ))}
    </Row>
    </div>
  )
}

export default ContactCards