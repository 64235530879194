import React, { useState, useEffect }  from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormikTextInput from './formik/TextInput';
import FormikTextArea from './formik/TextArea';
import * as Yup from 'yup';
import { API_URL } from "../../constants";
import { Container, Button } from 'react-bootstrap';

const SiteRegistration = ({callback}) => {
    const history = useHistory();
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [email, setEmail] = useState('');

    const postRequest = async(data, formReset) => {
        let response = await fetch(`${API_URL}/api/authentication/request-access/`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })

        if(response.status === 200) {
            callback(true);
        } else if(response.status === 409) {
            alert("An account with this username/email already exists. Use the login page or the password reset form to access this account.")
            callback(false);
            setFormSubmitting(false);
        } else {
            formReset();
            callback(false);
            setFormSubmitting(false);
        }
    }

    const cancelForm = () => {
        history.push('/')
    }

  return (
    <Container>
        <Formik
        initialValues={{
            username: '',
            email: '',
            password: '',
            password2: '',
            first_name: '',
            last_name: '',
            affiliation: '',
            rationale: ''
        }}
        validationSchema={Yup.object({
            username: Yup.string().email("Email format is not valid").required("Please enter your email"),
            username: Yup.string().email().required("Please enter your email"),
            password: Yup.string().required("No password entered")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
            password2: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
            first_name: Yup.string().required("Please enter your first name."),
            last_name: Yup.string().required("Please Enter your last name."),
            affiliation: Yup.string().required("Enter your Company/Ministry/Employer"),
            rationale: Yup.string().required("Enter why you need access to this site.")
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
            setFormSubmitting(true);
            setSubmitting(true);
            postRequest(values, setSubmitting, resetForm);
        }}
        >
            <Form>
                <div className="position-relative contact-form" controlid="contactTitle">
                    <FormikTextInput
                        label="Email (used as username to login)"
                        name="username"
                        type="text"
                        onBlur={(e) => setEmail(e.target.value)}
                        placeholder="someone@email.com"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                    <FormikTextInput
                        name="email"
                        value={email}
                        type="hidden"
                        placeholder="someone@email.com"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                    <FormikTextInput
                        label="Password"
                        name="password"
                        type="password"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                    <FormikTextInput
                        label="Verify Password"
                        name="password2"
                        type="password"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                    <FormikTextInput
                        label="First Name"
                        name="first_name"
                        type="text"
                        placeholder="First Name"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                    <FormikTextInput
                        label="Last Name"
                        name="last_name"
                        type="text"
                        placeholder="Last Name"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                    <FormikTextInput
                        label="Employer Name"
                        name="affiliation"
                        type="text"
                        placeholder="Company/Ministry/Organization"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                    <FormikTextArea
                        label="Reason access is required"
                        name="rationale"
                        type="textarea"
                        placeholder="Enter a brief description explaining why you need access to this portal."
                        className="w-75 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                </div>
                <Container className="mx-auto d-flex justify-content-center">
                    <Button className="my-3 mx-2" type="submit" disabled={formSubmitting}>Submit</Button>
                    <Button className="my-3 mx-2" onClick={cancelForm}>Cancel</Button>
                </Container>
            </Form>
        </Formik>
    </Container>
  )
}

export default SiteRegistration