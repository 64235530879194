import React from 'react';
import { Button } from 'react-bootstrap';

const LoadDefaultValues = ({loadDefaultValues}) => {

  return (
    <div>
        <Button variant="secondary" className="my-3 mx-2" onClick={loadDefaultValues}>Load Default Form Values</Button>
    </div>
  )
}

export default LoadDefaultValues