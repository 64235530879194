import React from 'react';
import { useLocation } from 'react-router-dom';
import PasswordReset from '../components/forms/PasswordReset';

const PasswordResetPage = () => {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token")
  return (
    <div>
        <PasswordReset token={token} />
    </div>
  )
}

export default PasswordResetPage