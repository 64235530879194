import React, { useState }  from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormikTextInput from './formik/TextInput';
import * as Yup from 'yup';
import { API_URL } from "../../constants";
import { Container, Button } from 'react-bootstrap';

const PasswordResetRequest = ({setShowForm, setShowModal}) => {
    const history = useHistory();
    const [formSubmitting, setFormSubmitting] = useState(false);

    const postPasswordReset = async(data, callback, formReset) => {
        let response = await fetch(`${API_URL}/api/authentication/password_reset/`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })

        if(response.status === 200) {
            callback(false);
            console.log("Submitted from successfully")
            setShowForm(false);
        } else {
            formReset();
            callback(false);
            setFormSubmitting(false);
        }
    }

    const cancelForm = () => {
        setShowModal(false)
    }

  return (
    <Container>
        <Formik
        initialValues={{
            email: ''
        }}
        validationSchema={Yup.object({
            email: Yup.string().email().required()
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
            setFormSubmitting(true);
            setSubmitting(true);
            postPasswordReset(values, setSubmitting, resetForm);
        }}
        >
            <Form>
                <div className="position-relative contact-form" controlid="contactTitle">
                    <FormikTextInput
                        label="Email"
                        name="email"
                        type="text"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                </div>
                <Container className="mx-auto d-flex justify-content-center">
                    <Button className="my-3 mx-2" type="submit" disabled={formSubmitting}>Submit</Button>
                    <Button className="my-3 mx-2" onClick={cancelForm}>Cancel</Button>
                </Container>
            </Form>
        </Formik>
    </Container>
  )
}

export default PasswordResetRequest