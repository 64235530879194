import {useEffect} from 'react';
import { useMap } from "react-leaflet";
import L from 'leaflet';

const MapZoom = ({geoJson}) => {
    const map = useMap();

    useEffect(() => {
      if (geoJson.length) {
        let featureLayer = new L.FeatureGroup();
        if (geoJson.length) {
          for (let i = 0; i < geoJson.length; i++) {
            let newLayer = L.geoJSON(geoJson[i]);
            featureLayer.addLayer(newLayer);
          }
        } else {
          let newLayer = L.geoJSON(geoJson);
          featureLayer.addLayer(newLayer);
        }
        map.fitBounds(featureLayer.getBounds());
        if (map.getZoom() > 17) {
            map.setZoom(15);
        }
      }
    }, [geoJson])
    
  return (
    null
  )
}

export default MapZoom