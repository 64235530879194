import React, {useContext, useState, useEffect} from "react";
import AuthContext from '../context/AuthContext';
import { API_URL } from "../constants";
import CreateContact from '../components/forms/CreateContact';
import UpdateContact from '../components/forms/UpdateContact';
import ContactCards from "../components/cards/ContactCards";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from "react-bootstrap";

function ContactsPage() {
    const [contacts, setContacts] = useState([]);
    const [visCreateContact, setCreateContactVis] = useState(false);
    const [visUpdateContact, setUpdateContactVis] = useState(false);
    const [contactDetail, setContactDetail] = useState([])
    const [contactsSearch, setContactsSearch] = useState('')

    let {user, authTokens} = useContext(AuthContext);

    useEffect(() => {
        if (!visCreateContact && !visUpdateContact) {
            getContacts();
        }
    }, [visCreateContact, visUpdateContact])

    useEffect(() => {
        getContacts();
    }, [contactsSearch])

    let getContacts = async() => {
        let fetchURL = '';
        if (contactsSearch !== ''){
            fetchURL = `${API_URL}/api/landscms/contacts-search/?search=${contactsSearch}`
        } else {
            fetchURL = `${API_URL}/api/landscms/contacts/`
        }
        let response = await fetch(fetchURL, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();
        if(response.status === 200) {
            setContacts(data);
        } else if (response.statusText === 'Unauthorized') {
            setContacts([])
        }
    }

    let updateContactList = () => {
        getContacts();
    }
    

    let deleteContact = async(contact_id) => {
        let fetch_url = `${API_URL}/api/landscms/contacts/${contact_id}/`
        let response = await fetch(fetch_url, {
            method: 'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            body: {
                'contact_id': contact_id
            }
        })
        if(response.status === 200) {
            updateContactList();
        } else if (response.statusText === 'Unauthorized') {
            setContacts([])
        } else {
            setContacts([])
        }
    }

    let getSearchContact = async(e) => {
        e.preventDefault();
        let qs = e.target.form[0].value;
        setContactsSearch(qs);
    }

    let getContact = async(contact_id) => {

        setCreateContactVis(false);
        
        if (visUpdateContact) {
            setUpdateContactVis(false);
        } 

        let fetch_url = `${API_URL}/api/landscms/contacts/${contact_id}/`
        let response = await fetch(fetch_url, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })
        let data = await response.json();
        if(response.status === 200) {
            setContactDetail(data);
            setUpdateContactVis(true);
        } else if (response.statusText === 'Unauthorized') {
            alert("You do not have permissions to edit this contact.")
        } else {
            console.log(response)
        }
    }

    
    return (
        <div>
            <h3>Hi {user.username} - These are your contacts:</h3>
            <form>
                <Container>
                    <input type="text" name="search_string" placeholder="Search (Name/Phone/Email/Company)" style={{"width": "280px"}}></input>
                    <Button type="submit" className="btn-sm mb-1 mx-1" variant="success" onClick={(e) => getSearchContact(e)}>Search</Button>
                </Container>
            </form>
            <Container fluid>
                <Row>
                    <Col sm={4}>
                        {
                            visCreateContact ? 
                            <CreateContact
                                updateState={setCreateContactVis}
                                contact={contactDetail}
                                className={`'d-none' ${visCreateContact ? "d-block" : "d-none"}`}
                            /> 
                            : <div></div>
                        }
                        {
                            visUpdateContact ? 
                            <UpdateContact 
                                updateState={setUpdateContactVis}
                                contact={contactDetail}
                                className={`'d-none' ${visUpdateContact ? "d-block" : "d-none"}`}
                            /> 
                            : <div></div>
                        }
                        {
                            !visUpdateContact && !visCreateContact ?
                            <button 
                                type="button" 
                                onClick={() => visCreateContact ? setCreateContactVis(false): setCreateContactVis(true)}>
                                    {visCreateContact ? "Hide Contact Form": "Create Contact"}
                            </button>
                            : <div></div>
                        }
                    </Col>
                    <Col sm={8}>
                        <ContactCards contacts={contacts} getContact={getContact} deleteContact={deleteContact} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactsPage