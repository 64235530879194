import { useContext, useState, useEffect } from "react";
import AuthContext from '../../context/AuthContext';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/esm/Container";
import { Formik, Form } from 'formik';
import FormikTextInput from './formik/TextInput';
import FormikSingleSelect from './formik/SingleSelect';
import FormikTextArea from './formik/TextArea';
import * as Yup from 'yup';
import { API_URL } from "../../constants";


const CreateCompany = ({childToParent, updateState, ...props}) => {
    let [countries, setCountries] = useState([]);
    let [provinces, setProvinces] = useState([]);
    let [formSubmitting, setFormSubmitting] = useState(false);
    let {authTokens, logoutUser} = useContext(AuthContext);

    useEffect(() => {
        getCountries();
    }, [])

    useEffect(() => {
        getProvinces();
    }, [])

    const cancelForm = () => {
        updateState(false);
    }

    let getCountries = async() => {
        let response = await fetch(`${API_URL}/api/landscms/countries/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();
        if(response.status === 200) {
            setCountries(JSON.parse(data));
        } else if (response.statusText === 'Unauthorized') {
            logoutUser();
        }
    }

    let getProvinces = async() => {
        let response = await fetch(`${API_URL}/api/landscms/provinces/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();
        if(response.status === 200) {
            setProvinces(JSON.parse(data));
        } else if (response.statusText === 'Unauthorized') {
            logoutUser();
        }
    }

    let postCompany = async(data, callback, formReset) => {
        let response = await fetch(`${API_URL}/api/landscms/organizations/`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(data)
        })

        if(response.status === 200) {
            callback(false);
            formReset();
        } else if (response.statusText === 'Unauthorized') {
            alert("Not Authorized to create contacts");
        } else {
            alert("Could not add a new contact.")
        }
        updateState(false);
    }

  return (
    <div>
    <Container {...props}>
        <h4>Add Company</h4>
        <Formik
            initialValues={{
                name:'',
                address: '',
                address_b: '',
                city: '',
                prov: '',
                country: '',
                postal_code: '',
                phone: '',
                fax: '',
                website: '',
                comments: ''
            }}
            validationSchema={Yup.object({
                name: Yup.string().required("Please enter a company name.").test({
                    message: 'Address can not be longer than 256 characters',
                    test: (value) => value == null || value.length <= 255,
                }),
                address: Yup.string().nullable().test({
                    message: 'Address can not be longer than 255 characters',
                    test: (value) => value == null || value.length <= 255,
                }),
                address_b: Yup.string().nullable().test({
                    message: 'Address can not be longer than 255 characters',
                    test: (value) => value == null || value.length <= 255,
                }),
                city: Yup.string().required().test({
                    message: 'City name must be less than 50 characters',
                    test: (value) => value == null || value.length <= 50,
                }),
                prov: Yup.string().required(),
                country: Yup.string().required("Please select a country"),
                postal_code: Yup.string().nullable()
                    .matches(
                            /(([A-Z])([0-9])([A-Z])([0-9])([A-Z])([0-9]))|([0-9]{5})/,
                            "Must Match X#X#X#X or #####"
                        ),
                phone: Yup.string().nullable().matches(
                    /([0-9]{3})-([0-9]{3})-([0-9]{4})/,
                    "Must Match ###-###-####"
                ),
                fax: Yup.string().nullable().matches(
                    /([0-9]{3})-([0-9]{3})-([0-9]{4})/,
                    "Must Match ###-###-####"
                ),
                website: Yup.string()
                .url("Ensure https:// or http:// is included")
                .nullable()
                .test({
                    message: 'URL Can not be longer than 255 characters',
                    test: (value) => value == null || value.length <= 255,
                }),
                comments: Yup.string().nullable()
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                setFormSubmitting(true);
                postCompany(values, setSubmitting, resetForm);
            }}
        >
            <Form>
                <div className="position-relative contact-form" controlid="companyForm">
                    <FormikTextInput
                        label="Company Name"
                        name="name"
                        type="text"
                        placeholder="Company Name"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Address"
                        name="address"
                        type="text"
                        placeholder="Address"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Address 2"
                        name="address_b"
                        type="text"
                        placeholder="Address 2"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="City"
                        name="city"
                        type="text"
                        placeholder="City"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikSingleSelect 
                        label="Province" 
                        name="prov"
                        className="w-75 mx-auto my-1"
                    >
                        <option value="">Select Province</option>
                        {provinces.map(p => ( 
                            <option key={p.label} value={p.value}>{p.label}</option>
                        ))}
                    </FormikSingleSelect>
                    <FormikSingleSelect 
                        label="Country" 
                        name="country"
                        className="w-75 mx-auto my-1"
                    >
                        <option value="">Select Country</option>
                        {countries.map(c => ( 
                            <option key={c.label} value={c.value}>{c.label}</option>
                        ))}
                    </FormikSingleSelect>
                    <FormikTextInput
                        label="Postal Code"
                        name="postal_code"
                        type="text"
                        placeholder="A1A2B2/12345"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Phone Number"
                        name="phone"
                        type="text"
                        placeholder="123-456-7890"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Fax Number"
                        name="fax"
                        type="text"
                        placeholder="123-456-7890"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Website"
                        name="website"
                        type="text"
                        placeholder="https://www.example.com"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextArea
                        label="Comments"
                        name="comments"
                        type="textarea"
                        placeholder="Comments"
                        className="w-75 mx-auto my-1"
                    />
                </div>
                <Container className="mx-auto d-flex justify-content-center">
                    <Button className="my-3 mx-2" type="submit" disabled={formSubmitting}>Save</Button>
                    <Button className="my-3 mx-2" onClick={cancelForm} disabled={formSubmitting}>Cancel</Button>
                </Container>
            </Form>
        </Formik>
    </Container>
    </div>
  )
}

export default CreateCompany