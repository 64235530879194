import React, { useState } from 'react';
import SiteRegistration from '../components/forms/SiteRegistration';

const RegistrationPage = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <div className='mt-5'>
        <h3>DRFN Consultation Portal Request Form</h3>
        {
          !formSubmitted ? 
          <SiteRegistration callback={setFormSubmitted} />
          : 
          <div>
            <p>Your request for a Consultation Portal account has been submitted. Once an adminstrator approves your account, you will receive an email with login information.</p>
          </div>
        }
    </div>
  )
}

export default RegistrationPage