import React, {useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import PasswordResetRequest from '../forms/PasswordResetRequest';

const PasswordResetModal = ({showModal, setShowModal}) => {
    const [showForm, setShowForm] = useState(true);
    const handleClose = () => setShowModal(false);

  return (
    <div>
        <Modal
            show={showModal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-center mx-auto'>Request Password Reset Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    showForm ? <PasswordResetRequest setShowForm={setShowForm} setShowModal={setShowModal} /> : 
                    <div>
                        <p>
                            Your password reset request has been submitted. 
                            If your account exists, you will recieve and email with a secure password reset link.
                        </p>
                        <Button onClick={handleClose}>Close</Button>
                    </div>
                }
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default PasswordResetModal