import React, {useContext, useState, useEffect} from 'react';
import AuthContext from '../../context/AuthContext';
import { useFormikContext } from 'formik';
import { API_URL } from '../../constants';
import SaveDefaultValues from './formik/SaveDefaultValues';
import LoadDefaultValues from './formik/LoadDefaultValues';

const FormDefaults = ({form_name}) => {
    const {values, setFieldValue} = useFormikContext();
    const {authTokens} = useContext(AuthContext);
    const [defaultFormValues, setDefaultFormValues] = useState([])

    useEffect(() => {
        postDefaultValues(true)
    }, [])

    const postDefaultValues = async(get_values=false) => {
        let fetchUrl = `${API_URL}/api/user-default-form-values/?form_name=${form_name}&get_values=${get_values}`;
        let response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(values)
        })

        let data = await response.json()
        if(response.status === 200) {
            setDefaultFormValues(data.form_values);
        } else {
            console.log("Form values not retrieved");
        }
    }

    const loadDefaultValues = () => {
        const keys = Object.keys(defaultFormValues);
        keys.forEach((key, index) => {
            setFieldValue(key, defaultFormValues[key])
        })
    }

  return (
    <>
        <SaveDefaultValues postDefaultvalues={postDefaultValues} />
        { defaultFormValues.length === 0 ? 
            null :
            <LoadDefaultValues loadDefaultValues={loadDefaultValues} />
        }
    </>
  )
}

export default FormDefaults