import React, { useContext, useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import AuthContext from '../../context/AuthContext';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/esm/Container";
import { Formik, Form } from 'formik';
import FormikTextInput from './formik/TextInput';
import FormikSingleSelect from './formik/SingleSelect';
import Checkbox from "./formik/Checkbox";
import TextEditor from "./formik/TextEditor";
import LeafletMap from "../LeafletMap";
import * as Yup from 'yup';
import NotificationModal from "../modals/NotificationModal";
import { API_URL } from "../../constants";
import FormDefaults from "./FormDefaults";

const UpdateConsultation = ({consult, childToParent, updateState, ...props}) => {
    let {authTokens, logoutUser} = useContext(AuthContext);
    const history = useHistory();
    let [projectName, setProjectName] = useState(consult.file_name);
    let [referralTypes, setReferralTypes] = useState([]);
    let [authorityTypes, setAuthorityTypes] = useState([]);
    let [industryTypes, setIndustryTypes] = useState([]);
    let [industrySubtypes, setIndustrySubtypes] = useState([]);
    let [contacts, setContacts] = useState([]);
    let [projectStatus, setProjectStatus] = useState([]);
    let [registeredContacts, setRegisteredContacts] = useState([]);
    let [companies, setCompanies] = useState([]);
    let [industrySubtypesVis, setIndustrySubtypesVis] = useState(false);

    const defaultDueDate = (addDays) => {
        let d = new Date();
        d.setDate(d.getDate() + addDays);
        return d;
    }

    useEffect(() => {
        getReferralTypes();
        getAuthorityTypes();
        getIndustryTypes();
        getContacts();
        getUsers();
        getCompanies();
        getProjectStatus();
    }, [])

    useEffect(() => {
        if(consult.industry_subtype) {
            setIndustrySubtypesVis(true);
            setIndustrySubtypes([consult.industry_subtype]);
        }
    }, [consult])

    let getContacts = async() => {
        let response = await fetch(`${API_URL}/api/landscms/contacts/?`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();
        if(response.status === 200) {
            setContacts(data);
        } else if (response.statusText === 'Unauthorized') {
            setContacts([]);
        }
    }

    let getProjectStatus = async() => {
        let response = await fetch(`${API_URL}/api/landscms/project-status/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();
        if(response.status === 200) {
            setProjectStatus(data);
        } else if (response.statusText === 'Unauthorized') {
            setProjectStatus([])
        }
    }

    let getUsers = async() => {
        let response = await fetch(`${API_URL}/api/landscms/contacts/?is_registered=true&consult_id=${consult.referral_id}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();
        if(response.status === 200) {
            setRegisteredContacts(data);
        } else if (response.statusText === 'Unauthorized') {
            setRegisteredContacts([])
        }
    }

    let getCompanies = async() => {
        let response = await fetch(`${API_URL}/api/landscms/organization-list/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();

        if(response.status === 200) {
            setCompanies(data);
        } else if (response.statusText === 'Unauthorized') {
            setCompanies([]);
        }
    }

    let getReferralTypes = async() => {
        let response = await fetch(`${API_URL}/api/landscms/referral-types/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();

        if(response.status === 200) {
            setReferralTypes(data);
        } else if (response.statusText === 'Unauthorized') {
            setReferralTypes([]);
        }
    }

    let getAuthorityTypes = async() => {
        let response = await fetch(`${API_URL}/api/landscms/authority-types/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();

        if(response.status === 200) {
            setAuthorityTypes(data);
        } else if (response.statusText === 'Unauthorized') {
            setAuthorityTypes([]);
        }
    }

    let getIndustryTypes = async() => {
        let response = await fetch(`${API_URL}/api/landscms/industry-types/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();

        if(response.status === 200) {
            setIndustryTypes(data);
        } else if (response.statusText === 'Unauthorized') {
            setIndustryTypes([]);
        }
    }

    let getIndustrySubypes = async(industry_type_pk) => {
        if (industry_type_pk) {
            let response = await fetch(`${API_URL}/api/landscms/industry-subtype?industry_type_id=${industry_type_pk}`, {
                method: 'GET',
                headers: {
                    'Content-Type':'application/json',
                    'Authorization':'Bearer ' + String(authTokens.access)
                }
            })
    
            let data = await response.json();
    
            if(response.status === 200) {
                setIndustrySubtypes(data);
                if (data.length > 0) {
                    setIndustrySubtypesVis(true);
                } else {
                    setIndustrySubtypesVis(false);
                }
            } else if (response.statusText === 'Unauthorized') {
                setIndustrySubtypes([])
            } else {
                console.log(response);
            }
        } else {
            setIndustrySubtypes([])
        }
    }

    let updateConsult = async(data, callback, formReset) => {
        let fetchUrl = `${API_URL}/api/landscms/consults/${data.referral_id}/`;
        let response = await fetch(fetchUrl, {
            method: 'PUT',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(data)
        })

        if(response.status === 200) {
            callback(false);
            history.push('/consultation/' + consult.referral_id)
        } else if (response.statusText === 'Unauthorized') {
            logoutUser();
            history.push('/login')
        } else if (response.status === 403) {
            console.log("response.data");
        }  else {
            console.log("Consult update request error.");
        }
    }

    const handleCancel = () => {
        props.putEditing(consult.referral_id, false);
        history.goBack();
    }

  return (
    <>
        {
            !projectName ? 
            <h4>Update Details for File: {consult.file_name}</h4>
            : <h4>Update Details for File: {projectName}</h4>
        }
        <Formik
            enableReinitialize
            initialValues={{
                referral_id: consult.referral_id ? consult.referral_id : '',
                file_name: consult.file_name ? consult.file_name : '',
                authority_ref_no: consult.authority_ref_no ? consult.authority_ref_no : '',
                revision_num: consult.revision_num ? consult.revision_num: 0,
                prj_name: consult.prj_name ? consult.prj_name : '',
                authority: consult.authority ? consult.authority.authority_id : '',
                contact: consult.contact ? consult.contact.contact_id : '',
                org: consult.org ? consult.org.org_id: '',
                org_ref_no: consult.org_ref_no ? consult.org_ref_no : '',
                ref_type: consult.ref_type ? consult.ref_type.ref_type_id : '',
                industry_type: consult.industry_type ? consult.industry_type.industry_type_id : '',
                industry_subtype: consult.industry_subtype ? consult.industry_subtype.subtype_id : '',
                location_desc: consult.location_desc ? consult.location_desc : '',
                primary_staff: consult.primary_staff ? consult.primary_staff.pk : '',
                date_application: consult.date_application ? consult.date_application : new Date(),
                date_due: consult.date_due ? consult.date_due : defaultDueDate(30),
                disturb_tot_ha: consult.disturb_tot_ha ? consult.disturb_tot_ha : '',
                disturb_crown_ha: consult.disturb_crown_ha ? consult.disturb_crown_ha : '',
                disturb_priv_ha: consult.disturb_priv_ha ? consult.disturb_priv_ha : '',
                new_cut_ha: consult.new_cut_ha ? consult.new_cut_ha : '',
                moti_area_ha: consult.moti_area_ha ? consult.moti_area_ha : '',
                description: consult.description ? consult.description : '',
                status: consult.status ? consult.status.id : '',
                water_m3_day: consult.water_m3_day ? consult.water_m3_day : '',
                water_m3_year: consult.water_m3_year ? consult.water_m3_year : '',
                est_start_date: consult.est_start_date ? consult.est_start_date : '',
                est_comp_date: consult.est_comp_date ? consult.est_comp_date : '',
                permitted: consult.permitted ? consult.permitted : false
            }}
            validationSchema={Yup.object({
                file_name: Yup.string().required("Required Field").test({
                    message: 'File name must be less than 51 characters',
                    test: (value) => value == null || value.length <= 50,
                }),
                authority_ref_no: Yup.string().required("Required Field").test({
                    message: 'Reference number must be less than 51 characters',
                    test: (value) => value == null || value.length <= 50,
                }),
                revision_num: Yup.number().integer(),
                prj_name: Yup.string().test({
                    message: 'Reference number must be less than 256 characters',
                    test: (value) => value == null || value.length <= 255,
                }),
                authority: Yup.number().integer().required("Please Select an Approval Authority"),
                org_ref_no: Yup.string().test({
                    message: 'Reference number must be less than 51 characters',
                    test: (value) => value == null || value.length <= 50,
                }),
                ref_type: Yup.number().integer().required("Please select a Referral Type"),
                industry_type: Yup.number().integer().required("Please select an Industry Type"),
                industry_subtype: Yup.number().integer().required("Please select an Industry Subtype"),
                location_desc: Yup.string().test({
                    message: 'Description must be less than 501 characters',
                    test: (value) => value == null || value.length <= 500,
                }),
                primary_staff: Yup.number().integer().required("Please select a person responsible for this consult entry"),
                date_application: Yup.date().required("Please enter an application date"),
                date_due: Yup.date().required("Please enter a requested response day at least a week from today"),
                disturb_crown_ha: Yup.number(),
                disturb_priv_ha: Yup.number(),
                disturb_tot_ha: Yup.number(),
                new_cut_ha: Yup.number(),
                moti_area_ha: Yup.number(),
                description: Yup.string().required("Please enter a description or summary of the consultation, project or application"),
                water_m3_day: Yup.number().integer(),
                water_m3_year: Yup.number().integer(),
                est_start_date: Yup.date(),
                est_comp_date: Yup.date()
            })}
            onSubmit={(values, {setSubmitting, resetForm} ) => {
                setSubmitting(true);
                updateConsult(values, setSubmitting, resetForm);
                childToParent();
            }}
        >
        <Form>
            <div controlid='projectForm' style={{"maxHeight": "80vh", "overflow": "auto"}} className="p-3">
                <Row className='w-100 mx-auto'>
                    <Col className='border border-3 border-dark p-2 m-2' lg>
                        <h4>Project Details</h4>
                        <FormikTextInput
                            name="referral_id"
                            type="hidden"
                        />
                        <Row>
                            <Col>
                                <FormikSingleSelect
                                    label="Project Status"
                                    name="status"
                                    info="Data/Information Entry state of this consult/project"
                                    className="mx-auto my-1"
                                >
                                    <option value="">Set the status of this consult entry.</option>
                                    {projectStatus.map(t => ( 
                                        <option key={t.id} value={t.id}>{t.status}</option>
                                    ))}
                                </FormikSingleSelect>
                            </Col>
                            <Col>
                                <FormikSingleSelect
                                    label="Consultation Type"
                                    name="ref_type"
                                    info="Enter Consultation/Information level of this project/consult. NOTE: This is a recommendation. The First Nation ultimately determines the acceptable level of consultation."
                                    className="mx-auto my-1"
                                >
                                    <option value="">Select a consultation type</option>
                                    {referralTypes.map(t => ( 
                                        <option key={t.ref_type_id} value={t.ref_type_id}>{t.ref_type}</option>
                                    ))}
                                </FormikSingleSelect>
                            </Col>
                        </Row>
                        <FormikTextInput
                            label="File Name"
                            name="file_name"
                            type="text"
                            placeholder="File Name"
                            className="mx-auto my-1"
                            onBlur={(e) => setProjectName(e.target.value)}
                            onKeyPress={(e) => setProjectName(e.target.value)}
                        />
                        <Row>
                            <Col>  
                                <FormikTextInput 
                                    label="Reference Number"
                                    name="authority_ref_no"
                                    type="text"
                                    className="mx-auto my-1"
                                />
                            </Col>
                            <Col>
                                <FormikTextInput 
                                    label="Revision"
                                    name="revision_num"
                                    type="number"
                                    className="mx-auto my-1"
                                />
                            </Col>
                            <Col className="my-auto">
                                <Checkbox
                                    label="Permitted"
                                    name="permitted"
                                />
                            </Col>
                        </Row>
                        <FormikTextInput 
                            label="Legal Land Description"
                            name="location_desc"
                            type="text"
                            className="mx-auto my-1"
                        />
                        <FormikTextInput 
                            label="Proponent's Project Name"
                            name="prj_name"
                            type="text"
                            className="mx-auto my-1"
                        />
                        <FormikSingleSelect
                            label="Industry Type"
                            name="industry_type"
                            className="mx-auto my-1"
                            onBlur={(e) => getIndustrySubypes(e.target.value)}
                        >
                            <option value="">Select an Industry Type</option>
                            {industryTypes.map(t => ( 
                                <option key={t.industry_type_id} value={t.industry_type_id}>{t.industry_type}</option>
                            ))}
                        </FormikSingleSelect>
                        {industrySubtypesVis ? 
                        <FormikSingleSelect
                            label="Industry Subtype"
                            name="industry_subtype"
                            className="mx-auto my-1"
                        >
                            <option value="">Select an Industry Subtype</option>
                                {industrySubtypes.map(t => ( 
                                    <option key={t.subtype_id} value={t.subtype_id}>{t.development_type}</option>
                                ))}
                        </FormikSingleSelect>
                        : <div></div>}
                    </Col>
                    <Col className='border border-3 border-dark p-2 m-2' lg>
                        <h4>Permitting Authority Details</h4>
                        <FormikSingleSelect
                            label="Permitting Authority"
                            name="authority"
                            info="Ministry/Branch/Organization responsible for issuing this permit/permission."
                            className="mx-auto my-1"
                        >   
                            <option value="">Select a Permitting Authority</option>
                            {authorityTypes.map(t => ( 
                                <option key={t.authority_id} value={t.authority_id}>{t.authority_name}</option>
                            ))}
                        </FormikSingleSelect>
                        <FormikSingleSelect
                            label="Primary Contact"
                            name="contact"
                            info="Contact to send responses, comments or concerns to."
                            className="mx-auto my-1"
                        >                            
                            <option value="">Select a Contact</option>
                            {contacts.map(c => ( 
                                <option key={c.contact_id} value={c.contact_id}>{c.l_name}, {c.f_name}</option>
                            ))}  
                        </FormikSingleSelect>
                        <FormikSingleSelect
                            label="Consult Administrator"
                            name="primary_staff"
                            info="Person responsible for uploading information and managing this entry."
                            className="mx-auto my-1"
                        >                            
                            <option value="">Select a Contact</option>
                            {registeredContacts.map(c => ( 
                                <option key={c.contact_id} value={c.contact_id}>{c.l_name}, {c.f_name}</option>
                            ))}
                        </FormikSingleSelect>
                        <h4 className="mt-5">Applicant/Proponent Details</h4>
                        <FormikSingleSelect
                            label="Applicant (select 'Individual/Sole Propritor' if the applicant is an individual)"
                            name="org"
                            className="mx-auto my-1"
                        >
                            <option value="">Select Company</option>
                            {companies.map(c => ( 
                                <option key={c.org_id} value={c.org_id}>{c.name}</option>
                            ))}
                        </FormikSingleSelect>
                        <FormikTextInput
                            label="Applicants Reference #"
                            name="org_ref_no"
                            type="text"
                            className="mx-auto my-1"
                        />
                    </Col>
                    <Col className='border border-3 border-dark p-2 m-2' lg>
                        <h4>Key Dates</h4>
                        <Row>
                            <Col className="m-auto">     
                                <FormikTextInput
                                    label="Application Date"
                                    name="date_application"
                                    type="date"
                                    className="mx-1"
                                />
                            </Col>
                            <Col className="m-auto">
                                <FormikTextInput
                                    label="Response Requested By:"
                                    name="date_due"
                                    type="date"
                                    className="mx-1"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="m-auto">    
                                <FormikTextInput
                                    label="Est. Project/Permit Start:"
                                    name="est_start_date"
                                    type="date"
                                    className="mx-1"
                                />
                            </Col>
                            <Col className="m-auto">
                                <FormikTextInput
                                    label="Est. Project/Permit End:"
                                    name="est_comp_date"
                                    type="date"
                                    className="mx-1"
                                />
                            </Col>
                        </Row>
                        <h4 className="mt-3">Technical Details</h4>
                        <h6>Disturbance (Ha.)</h6>
                        <Row className="justify-content-center">
                            <Col className="m-auto">    
                                <FormikTextInput
                                    label="Crown"
                                    name="disturb_crown_ha"
                                    type="number"
                                    className="mx-1"
                                />
                            </Col>
                            <Col className="m-auto">
                                <FormikTextInput
                                    label="Private"
                                    name="disturb_priv_ha"
                                    type="number"
                                    className="mx-1"
                                />
                            </Col>
                            <Col className="m-auto">
                                <FormikTextInput
                                    label="MOTI"
                                    name="moti_area_ha"
                                    type="number"
                                    className="mx-1"
                                />
                            </Col>
                            <Col className="m-auto">
                                <FormikTextInput
                                    label="Total New Cut"
                                    name="new_cut_ha"
                                    type="number"
                                    className="mx-1"
                                />
                            </Col>
                        </Row>
                        <h6 className="mt-3">Water (m^3)</h6>
                        <Row  className="justify-content-md-center">
                            <Col md>    
                                <FormikTextInput
                                    label="Daily Water"
                                    name="water_m3_day"
                                    type="number"
                                    className="mx-1"
                                />
                            </Col>
                            <Col md>
                                <FormikTextInput
                                    label="Annual Water"
                                    name="water_m3_year"
                                    type="number"
                                    className="mx-1"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='mx-0'>
                    <Col className='border border-3 border-dark p-2 m-2' lg>  
                        <FormikTextInput
                            name="description"
                            type="hidden"
                        />
                        <p>Project Description</p>
                        <TextEditor
                            initVal={consult.description}
                        />
                    </Col>
                    <Col className='border border-3 border-dark p-2 m-2' lg>
                        {/* <LeafletMap></LeafletMap> */}
                    </Col>
                </Row>
            </div>
                <Container className="mx-auto d-flex justify-content-center">
                    <Button variant="success" className="my-3 mx-2" type="submit">Save</Button>
                    <Button variant="danger" className="my-3 mx-2 btn-warning" onClick={handleCancel}>Cancel</Button>
                    {/* <SaveDefaultValues form_name="consultation_form"/>
                    <LoadDefaultValues form_name="consultation_form" consult={consult} /> */}
                    <FormDefaults form_name="consultation_form" />
                </Container>
                
            {
                props.editingSeconds < 60 ? 
                <NotificationModal 
                    title={`Editing session is going to end in ${props.editingSeconds} seconds`}
                    show={true}
                >
                   <Button className="my-3 mx-2" onClick={() => {props.updateEditingTime()}}>Continue Editing?</Button>
                </NotificationModal>
                : <div></div>
            }
        </Form>
        </Formik>
    </>
  )
}

export default UpdateConsultation