import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../constants';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import ConsultationCards from '../components/cards/ConsultationCards';
import { CreateProject } from '../components/forms/CreateProjectQuick';

const ConsultationsPage = () => {
  const [consults, setConsults] = useState([]);
  const [newConsults, setNewConsults] = useState(false);
  const [consultSearch, setConsultSearch] = useState('');
  let {user, authTokens, logoutUser} = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
      getConsults();
  }, [])

  useEffect(() => {
    if(newConsults) {
      getConsults();
    }
  }, [newConsults])

  let getSearchConsults = async(e) => {
      e.preventDefault();
      let qs = e.target.form[0].value;
      setConsultSearch(qs);
  }

  useEffect(() => {
    getConsults();
  }, [consultSearch])

  let getConsults = async() => {
    let fetchURL = '';
    if (consultSearch !== ''){
        fetchURL = `${API_URL}/api/landscms/consults-search/?search=${consultSearch}`
    } else {
        fetchURL = `${API_URL}/api/landscms/consults/`
    }

    let response = await fetch(fetchURL, {
        method: 'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization':'Bearer ' + String(authTokens.access)
        }
    })

    let data = await response.json();
    if(response.status === 200) {
        setConsults(data);
    } else if (response.statusText === 'Unauthorized') {
        setConsults([]);
        logoutUser();
        history.push('/login/');
    }
  }

  return (
  <div>
    <h3 className='mb-3'>Hi {user.username} - These are your Consultations:</h3>
    <form>
        <Container>
            <input type="text" name="search_string" placeholder="Search (File name, Ref #, owner, etc...)" style={{"width": "280px", textAlign: "center"}}></input>
            <Button type="submit" className="btn-sm mb-1 mx-1" variant="success" onClick={(e) => getSearchConsults(e)} >Search</Button>
        </Container>
    </form>
    <Container fluid>
      <Row>
          <Col sm={4}>
            <CreateProject updateState={setNewConsults}/>
          </Col>
          <Col sm={8}>
              <ConsultationCards consults={consults} />
          </Col>
      </Row>
    </Container>
  </div>
  )
}

export default ConsultationsPage