import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { API_URL } from "../constants";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

    const history = useHistory();

    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null
    );

    const [user, setUser] = useState(() =>
        localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null
    );

    const[loading, setLoading] = useState(true);

    let loginUser = async (e) => {
        e.preventDefault();
        try {
            let response = await fetch(`${API_URL}/api/token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify({
                    username: e.target.username.value, password: e.target.password.value
                })
            })

            let data = await response.json();

            if (response.status === 200) {
                setAuthTokens(data);
                setUser(jwt_decode(data.access));
                localStorage.setItem('authTokens', JSON.stringify(data))
                history.push('/')
            } else {
                alert('Login response failed.')
            }

        } catch (error) {
            console.log("User Login ASYNC: ", error)
        }
    } 

    let logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('authTokens');
        history.push('/login');
    }

    let updateToken = async () => {
        try {
            let response = await fetch(`${API_URL}/api/token/refresh/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    refresh: authTokens?.refresh
                })
            })

            let data = await response.json();

            if (response.status === 200) {
                setAuthTokens(data);
                setUser(jwt_decode(data.access));
                localStorage.setItem('authTokens', JSON.stringify(data));
                // history.push('/');
            } else {
                logoutUser();
            }

        } catch (error) {
            console.log("User Login ASYNC: ", error)
        }

        if(loading){
            setLoading(false);
        }
    }

    let contextData = {
        user:user,
        authTokens:authTokens,
        loginUser:loginUser,
        logoutUser:logoutUser
    }

    useEffect(() => {
        if(loading){
            setLoading(false)
        }

        const fourMinutes = 1000 * 60 * 4.5
        let interval = setInterval(() => {
            if(authTokens){
                updateToken();
            }
        }, fourMinutes)
        return () => { clearInterval(interval); }
    }, [authTokens, loading])

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )
}