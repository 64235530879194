import React, {useState, useEffect, useContext} from 'react';
import AuthContext from '../context/AuthContext';
import { Link } from 'react-router-dom';

const HomePage = () => {
    let {user} = useContext(AuthContext);
    return (
        <div className='Home-Div'>
            <h2>Welcome {user.username}!</h2>
            <Link to="/consultations">My Consultations</Link>
        </div>
    )
}

export default HomePage;