import React, { useContext, useState, useEffect } from "react";
import AuthContext from '../../context/AuthContext';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/esm/Container";
import { Formik, Form } from 'formik';
import FormikTextInput from './formik/TextInput';
import FormikSingleSelect from './formik/SingleSelect';
import FormikTextArea from './formik/TextArea';
import * as Yup from 'yup';
import { API_URL } from "../../constants";


const UpdateContact = ({contact, updateState, ...props}) => {

    let [countries, setCountries] = useState([]);
    let [provinces, setProvinces] = useState([]);
    let [companies, setCompanies] = useState([]);
    let {authTokens, logoutUser} = useContext(AuthContext);

    useEffect(() => {
        getCountries();
    }, [])

    useEffect(() => {
        getProvinces();
    }, [])

    useEffect(() => {
        getCompanies();
    }, [])

    const cancelForm = () => {
        updateState(false);
    }

    let getCountries = async() => {
        let response = await fetch(`${API_URL}/api/landscms/countries/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();
        if(response.status === 200) {
            setCountries(JSON.parse(data));
        } else if (response.statusText === 'Unauthorized') {
            logoutUser();
        }
    }

    let getProvinces = async() => {
        let response = await fetch(`${API_URL}/api/landscms/provinces/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();
        if(response.status === 200) {
            setProvinces(JSON.parse(data));
        } else if (response.statusText === 'Unauthorized') {
            logoutUser();
        }
    }

    let getCompanies = async() => {
        let response = await fetch(`${API_URL}/api/landscms/organization-list/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();

        if(response.status === 200) {
            setCompanies(data);
        } else if (response.statusText === 'Unauthorized') {
            logoutUser();
        }
    }

    let updateContact = async(data, callback) => {
        let response = await fetch(`${API_URL}/api/landscms/contacts/${data.contact_id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(data)
        })

        if(response.status === 200) {
            callback(false);
        } else if (response.statusText === 'Unauthorized') {
            alert("Not Authorized to create contacts");
        } else {
            alert("Could not add a new contact.")
        }
    }

    let showCompanySearch = (e) => {
        console.log(e);
        document.querySelector("#companySearch");
    }

  return (
    <Container {...props}>
        <h4>Update contact details for</h4> 
        <h4>{contact.l_name}, {contact.f_name}</h4>
        <Formik
            enableReinitialize
            initialValues={{
                contact_id: contact.contact_id,
                title: !contact.title ? '' : contact.title,
                salutation: !contact.salutation ? '' : contact.salutation,
                f_name: contact.f_name,
                l_name: contact.l_name,
                phone_work: contact.phone_work ? contact.phone_work : '',
                phone_mobile: contact.phone_mobile ? contact.phone_mobile : '',
                email: contact.email ? contact.email : '',
                suffix: !contact.suffix ? '' : contact.suffix,
                address: !contact.address? '' : contact.address,
                address_b: !contact.address_b ? '': contact.address_b,
                city: !contact.city ? '' : contact.city,
                prov: !contact.prov ? '' : contact.prov,
                country: !contact.country ? '' : contact.country,
                postal_code: !contact.postal_code ? '' : contact.postal_code,
                website: !contact.website ? '' : contact.website,
                birthdate: !contact.birthdate ? '': contact.birthdate,
                comments: !contact.comments ? '' : contact.comments,
                org: !contact.org ? '' : contact.org.org_id,
            }}
            validationSchema={Yup.object({
                title: Yup.string().nullable(),
                salutation: Yup.string().nullable(),
                f_name: Yup.string().required("Required Field").test({
                    message: 'Last name must be less than 51 characters',
                    test: (value) => value == null || value.length <= 50,
                }),
                l_name: Yup.string().required("Required Field").test({
                    message: 'Last name must be less than 51 characters',
                    test: (value) => value == null || value.length <= 50,
                }),
                phone_work: Yup.string().nullable().matches(
                    /([0-9]{3})-([0-9]{3})-([0-9]{4})/,
                    "Must Match ###-###-####"
                ).test({
                    message: 'Address can not be longer than 255 characters',
                    test: (value) => value == null || value.length <= 255,
                }),
                phone_mobile: Yup.string().nullable().matches(
                    /([0-9]{3})-([0-9]{3})-([0-9]{4})/,
                    "Must Match ###-###-####"
                ).test({
                    message: 'Address can not be longer than 255 characters',
                    test: (value) => value == null || value.length <= 255,
                }),
                email: Yup.string().email().nullable(),
                address: Yup.string().nullable().test({
                    message: 'Address can not be longer than 256 characters',
                    test: (value) => value == null || value.length <= 255,
                }),
                address_b: Yup.string().nullable().test({
                    message: 'Address can not be longer than 256 characters',
                    test: (value) => value == null || value.length <= 255,
                }),
                suffix: Yup.string().nullable().test({
                    message: 'Suffix can not be longer than 11 characters',
                    test: (value) => value == null || value.length <= 10,
                }),
                city: Yup.string().required().test({
                    message: 'City name must be less than 51 characters',
                    test: (value) => value == null || value.length <= 50,
                }),
                prov: Yup.string().required(),
                postal_code: Yup.string().nullable()
                    .matches(
                            /(([A-Z])([0-9])([A-Z])([0-9])([A-Z])([0-9]))|([0-9]{5})/,
                            "Must Match X#X#X#X or #####"
                        ),
                website: Yup.string()
                .url("Ensure https:// or http:// is included")
                .nullable()
                .test({
                    message: 'URL Can not be longer than 256 characters',
                    test: (value) => value == null || value.length <= 255,
                }),
                birthdate: Yup.date().nullable().max(new Date()),
                comments: Yup.string().nullable()
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                updateContact(values, setSubmitting);
                updateState(false);
              }}
        >
            <Form>
                <div className="position-relative contact-form" controlid="contactTitle">
                    <FormikTextInput
                        name="contact_id"
                        type="hidden"
                    />
                    <FormikTextInput
                        label="Title"
                        name="title"
                        type="text"
                        placeholder="Job Title"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="First Name"
                        name="f_name"
                        type="text"
                        placeholder="First Name"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Last Name"
                        name="l_name"
                        type="text"
                        placeholder="Last Name"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Suffix"
                        name="suffix"
                        type="text"
                        placeholder="Suffix (i.e. B. SC.)"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Work Phone/Primary"
                        name="phone_work"
                        type="text"
                        placeholder="123-456-7890"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Mobile Phone"
                        name="phone_mobile"
                        type="text"
                        placeholder="123-456-7890"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="someone@email.com"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Birthday"
                        name="birthdate"
                        type="date"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikSingleSelect 
                        label="Company" 
                        name="org"
                        className="w-75 mx-auto my-1"
                    >
                        <option value="">Select a company</option>
                        {companies.map(c => ( 
                            <option key={c.org_id} value={c.org_id}>{c.name}</option>
                        ))}
                    </FormikSingleSelect>
                    <FormikTextInput
                        label="Address"
                        name="address"
                        type="text"
                        placeholder="Address"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Address 2"
                        name="address_b"
                        type="text"
                        placeholder="Address 2"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="City"
                        name="city"
                        type="text"
                        placeholder="City"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikSingleSelect 
                        label="Province" 
                        name="prov"
                        className="w-75 mx-auto my-1"
                    >
                        <option value="">Select Province</option>
                        {provinces.map(p => ( 
                            <option key={p.label} value={p.value}>{p.label}</option>
                        ))}
                    </FormikSingleSelect>
                    <FormikSingleSelect 
                        label="Country" 
                        name="country"
                        className="w-75 mx-auto my-1"
                    >
                        <option value="">Select Country</option>
                        {countries.map(c => ( 
                            <option key={c.label} value={c.value}>{c.label}</option>
                        ))}
                    </FormikSingleSelect>
                    <FormikTextInput
                        label="Postal Code"
                        name="postal_code"
                        type="text"
                        placeholder="A1A2B2/12345"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextInput
                        label="Website"
                        name="website"
                        type="text"
                        placeholder="https://www.example.com"
                        className="w-75 mx-auto my-1"
                    />
                    <FormikTextArea
                        label="Comments"
                        name="comments"
                        type="textarea"
                        placeholder="Comments"
                        className="w-75 mx-auto my-1"
                    />
                </div>
                <Container className="mx-auto d-flex justify-content-center">
                    <Button className="my-3 mx-2" type="submit">Save</Button>
                    <Button className="my-3 mx-2" onClick={cancelForm}>Cancel</Button>
                </Container>
            </Form>
        </Formik>
    </Container>
  )
}

export default UpdateContact