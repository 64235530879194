import { GeoJSON } from "react-leaflet";
import { useMap } from "react-leaflet";
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';

const GeoJsonLayer = ({data, ...props}) => {
    const map = useMap();

    const setBounds = () => {
        let feature = L.geoJSON(data);
        map.fitBounds(feature.getBounds());
        if (map.getZoom() > 17) {
            map.setZoom(15);
        }
        return
    }

    return (
            <GeoJSON 
                data={data}
                eventHandlers={{
                add: () => {
                    if (props.setBounds){
                        setBounds();
                    }
                }
                }} 
            />
    )
    }

    export default GeoJsonLayer