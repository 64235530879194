import React, {useContext, useState, useEffect} from "react";
import AuthContext from '../context/AuthContext';
import { API_URL } from "../constants";
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from "react-bootstrap";

import CreateCompany from "../components/forms/CreateCompany";
import UpdateCompany from "../components/forms/UpdateCompany";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPen } from '@fortawesome/free-solid-svg-icons';

const CompaniesPage = () => {
    const [companies, setCompanies] = useState([]);
    const [visCreateCompany, setCreateCompanyVis] = useState(false);
    const [visUpdateCompany, setUpdateCompanyVis] = useState(false);
    const [companyDetail, setCompanyDetail] = useState([]);
    const [companySearch, setCompanySearch] = useState('');

    let {authTokens} = useContext(AuthContext);

    useEffect(() => {
        if (!visCreateCompany && !visUpdateCompany){
            getCompanies();
        }
    }, [visCreateCompany, visUpdateCompany]);

    useEffect(() => {
        getCompanies();
    }, [companySearch]);

    let getCompanies = async() => {
        let fetchURL = '';
        if (companySearch !== ''){
            fetchURL = `${API_URL}/api/landscms/companies-search/?search=${companySearch}`
        } else {
            fetchURL = `${API_URL}/api/landscms/organizations/`
        }

        let response = await fetch(fetchURL, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();
        if(response.status === 200) {
            setCompanies(data);
        } else if (response.statusText === 'Unauthorized') {
            setCompanies([]);
        }
    }

    let getSearchCompany = async(e) => {
        e.preventDefault();
        let qs = e.target.form[0].value;
        setCompanySearch(qs);
    }

    let getCompany = async(company_id) => {

        setCreateCompanyVis(false);
        
        if (visUpdateCompany) {
            setUpdateCompanyVis(false);
        } 

        let fetch_url = `${API_URL}/api/landscms/organizations/${company_id}/`
        let response = await fetch(fetch_url, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })
        let data = await response.json();
        if(response.status === 200) {
            setCompanyDetail(data);
            setUpdateCompanyVis(true);
        } else if (response.statusText === 'Unauthorized') {
            alert("You do not have permissions to edit this company.")
        } else {
            console.log(response)
        }
    }

    let deleteCompany = async(company_id) => {
        let fetch_url = `${API_URL}/api/landscms/organizations/${company_id}/`
        let response = await fetch(fetch_url, {
            method: 'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })
        if(response.status === 200) {
            getCompanies();
        } else if (response.statusText === 'Unauthorized') {
            setCompanies([])
        } else {
            alert("Company could not be deleted");
        }
    }

    return (
        <div>
            <h3>Registered Companies</h3>
            <form>
                <Container>
                    <input type="text" name="search_string" placeholder="Search (Name/Phone/Fax)" style={{"width": "280px", textAlign: "center"}}></input>
                    <Button type="submit" className="btn-sm mb-1 mx-1" variant="success" onClick={(e) => getSearchCompany(e)} >Search</Button>
                </Container>
            </form>
            <Container fluid>
                <Row>
                    <Col sm={4}>
                        {
                            visCreateCompany ? 
                            <CreateCompany
                                updateState={setCreateCompanyVis}
                                company={companyDetail}
                                className={`'d-none' ${visCreateCompany ? "d-block" : "d-none"}`}
                            /> 
                            : <div></div>
                        }
                        {
                            visUpdateCompany ? 
                            <UpdateCompany
                                updateState={setUpdateCompanyVis}
                                company={companyDetail}
                                className={`'d-none' ${visUpdateCompany ? "d-block" : "d-none"}`} 
                            /> 
                            : <div></div>
                        }
                        {
                            !visUpdateCompany && !visCreateCompany ?
                            <button 
                                type="button" 
                                onClick={() => visCreateCompany ? 
                                    setCreateCompanyVis(false)
                                    : setCreateCompanyVis(true)}>
                                {visCreateCompany ? "Hide Company Form": "Create Company"}
                            </button>
                            : <div></div>
                        }
                    </Col>
                    <Col sm={8}>
                        <Stack direction="horizontal" gap={3}>
                            {companies.map((company) => (
                                <Card key={company.org_id} value={company.org_id} style= {{ width: '250px'}}>
                                    <Card.Body>
                                        <Card.Title>{company.name}</Card.Title>
                                        <Card.Text className="mt-1">
                                            {company.address} 
                                            <br></br>
                                            {company.city}, {company.prov} {company.country}
                                        </Card.Text>
                                        <CardGroup className="mx-auto justify-content-center">
                                            <FontAwesomeIcon className="mx-1" style= {{ color: 'green'}}
                                            icon={faPen} onClick={() => getCompany(company.org_id)}
                                            title="Update Company Details"
                                            />
                                            <FontAwesomeIcon className="mx-1" style= {{ color: 'red'}}
                                            icon={faClose} onClick={() => deleteCompany(company.org_id)}
                                            title="Delete Company"
                                            />
                                        </CardGroup>
                                    </Card.Body>
                                </Card>
                            ))}
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CompaniesPage