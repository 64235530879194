import React, { useState, useEffect }  from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormikTextInput from './formik/TextInput';
import * as Yup from 'yup';
import { API_URL } from "../../constants";
import { Container, Button } from 'react-bootstrap';

const PasswordReset = ({token}) => {
    const history = useHistory();
    const [formSubmitting, setFormSubmitting] = useState(false);

    const postPassword = async(data, callback, formReset) => {
        let response = await fetch(`${API_URL}/api/authentication/password_reset/confirm/?token=${token}`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data)
        })

        if(response.status === 200) {
            history.push('/');
            callback(false);
            console.log("Submitted from successfully")
        } else {
            formReset();
            callback(false);
            setFormSubmitting(false);
            console.log(response);
        }
    }

    const cancelForm = () => {
        history.push('/')
    }

  return (
    <Container className='Login-Div'>
        <Formik
        initialValues={{
            password: '',
            password2: '',
            token: token,
        }}
        validationSchema={Yup.object({
            password: Yup.string().required("No password entered")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
            password2: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
            token: Yup.string().required()
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
            setFormSubmitting(true);
            setSubmitting(true);
            console.log(values);
            postPassword(values, setSubmitting, resetForm);
        }}
        >
            <Form>
                <div className="position-relative contact-form" controlid="contactTitle">
                    <FormikTextInput
                        label="New Password"
                        name="password"
                        type="password"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                    <FormikTextInput
                        label="Verify New Password"
                        name="password2"
                        type="password"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                    <FormikTextInput
                        name="token"
                        type="hidden"
                        className="w-50 mx-auto my-1"
                        style={{minWidth: "200px"}}
                    />
                </div>
                <Container className="mx-auto d-flex justify-content-center">
                    <Button className="my-3 mx-2" type="submit" disabled={formSubmitting}>Submit</Button>
                    <Button className="my-3 mx-2" onClick={cancelForm}>Cancel</Button>
                </Container>
            </Form>
        </Formik>
    </Container>
  )
}

export default PasswordReset