import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './context/AuthContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';


import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import PasswordResetPage from './pages/PasswordResetPage';
import ProfilePage from './pages/ProfilePage';
import Header from './components/Header';
import NotesPage from './pages/NotesPage';
import ContactsPage from './pages/ContactsPage';
import CompaniesPage from './pages/CompaniesPage';
import ConsultationsPage from './pages/ConsultationsPage';
import ConsultationDetail from './pages/ConsultationDetail';
import ConsultationEditing from './pages/ConsultationEditing';

function App() {
  return (
    <div className="App">
      <Router basename='/' forceRefresh={true}>
        <AuthProvider>
            <Header />
            <Switch>
              <PrivateRoute component={HomePage} exact path="/" />
              <Route component={LoginPage} path="/login"/>
              <Route component={RegistrationPage} path="/register"/>
              <Route component={PasswordResetPage} path="/password-reset"/>
              <PrivateRoute component={ProfilePage} path="/profile"/>
              <PrivateRoute component={NotesPage} path="/notes" />
              <PrivateRoute component={ContactsPage} path="/contacts" />
              <PrivateRoute component={CompaniesPage} path="/companies" />
              <PrivateRoute component={ConsultationsPage} path="/consultations" />
              <PrivateRoute component={ConsultationDetail} path="/consultation/:referral_id" />
              <PrivateRoute component={ConsultationEditing} path="/consultation-edit/:referral_id" />
            </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
