import React, {useState, useContext} from 'react';
import AuthContext from '../context/AuthContext';
import PasswordResetModal from '../components/modals/PasswordResetModal';
import { Button } from 'react-bootstrap';

const LoginPage = () => {
    let {loginUser} = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setShowModal(true);
        console.log("clicked")
        return
    }

    return (
        <div>
            {
                showModal ? 
                <PasswordResetModal showModal={showModal} setShowModal={setShowModal} /> : <div></div>
            }
            <div className='Login-Div'>
                <h3>DRFN Lands Consultation Portal Login</h3>
                <form onSubmit={loginUser} className="Login-Form">
                    <input className='pd-3' type="text" name="username" placeholder="Enter Username" /><br></br>
                    <input className='pd-3' type="password" name="password" placeholder="Enter Password" /><br></br>
                    <Button className='my-2' variant="dark" type="submit">Login</Button>
                </form>
                <p><a href="#" onClick={(e) => handleClick(e)}>Reset Password</a></p>
            </div>
        </div>
    )
}

export default LoginPage;
