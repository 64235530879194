import React, {useEffect, useState, useContext} from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Container from 'react-bootstrap/esm/Container';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import SubmitConsultModel from './modals/SubmitConsultModel';
import DocumentUpload from './forms/DocumentUpload';
import ConsultationFile from './ConsultationFile';
import GeoJsonLayer from './leaflet/GeoJsonLayer';

import LeafletMap from './LeafletMap';
import { LayersControl } from 'react-leaflet';
import MapZoom from './leaflet/MapZoom';

const ConsultationDetails = ({consult, ...props}) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [newDocs, setNewDocs] = useState([]);
  const [geoJson, setGeoJson] = useState([])
  const {user} = useContext(AuthContext)
  const [zoomGeojson, setZoomGeojson] = useState([]);
  const [selectedDocId, setSelectedDocId] = useState(null);

  const editLink = () => {
    history.push(`/consultation-edit/${consult.referral_id}/`);
  }

  const showSubmitModal = () => {
    if (showModal){
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }

  const docDeleted = (document_id) => {
    let tmpArray = [];
    for (let i = 0; i < geoJson.length; i++) {
      if (geoJson[i].document_id !== document_id){
        tmpArray.push(geoJson[i])
      }
    }
    setGeoJson(tmpArray);
  }

  useEffect(() => {
    for (let i = 0; i < geoJson.length; i++) {
      if (geoJson[i].document_id == selectedDocId){
        setZoomGeojson(geoJson[i]);
      }
    }
    setSelectedDocId(null);
  }, [selectedDocId])

  const htmlDecode = (input) => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  useEffect(() => {
    htmlDecode(consult.description);
  }, [])

  useEffect(() => {
    if (newDocs.is_gis && newDocs.doc_geojson.length > 0) {
      let gis_data = JSON.parse(newDocs.doc_geojson);
      gis_data["file_name"] = newDocs.f_name;
      gis_data["document_id"] = newDocs.document_id;
      setGeoJson((geoJson) => [...geoJson, gis_data])
    }
  }, [newDocs])

  useEffect(() => {
    for (let i = 0; i < consult.referral_doc.length; i++) {
      if (consult.referral_doc[i].is_gis) {
        if (consult.referral_doc[i].doc_geojson.length > 0) {
          let gis_data = JSON.parse(consult.referral_doc[i].doc_geojson);
          if (gis_data.features.length > 0) {
            let file_name = consult.referral_doc[i].f_name;
            let document_id = consult.referral_doc[i].document_id;
            gis_data["file_name"] = file_name;
            gis_data["document_id"] = document_id;
            setGeoJson((geoJson) => [...geoJson, gis_data])
          }
        }
      }
    }
  }, [])

  return (
    <Container fluid>
      <SubmitConsultModel setShowModal={setShowModal} showModal={showModal} consultId={consult.referral_id}/>
      <div controlid='projectDetail' className="p-3 consult-detail">
        <Row className='mx-auto consult-detail-row'>
          <Col className='border border-3 border-dark p-2 m-2' lg>
            <Row className='justify-content-md-center'>
              <Col md="auto"><h4>Project Details ({consult.status.status})</h4></Col>
              {
                consult.submitted || consult.editing_locked ? <div></div> : 
                <Col md lg="1">
                    <Button className='btn-sm' onClick={editLink} variant="primary">Edit</Button>
                </Col>
              }
              {
                consult.submitted || consult.editing_locked ? <div></div> : 
                <Col md lg="1">
                    <Button className='btn-sm mx-2' onClick={showSubmitModal} variant="success">Submit</Button>
                </Col>
              }
            </Row>
            <Row className="justify-content-md-left text-left">
              <Col md>
              <p className='my-0'><span className='text-bold'>Consultation Type:</span> 
              {consult.ref_type ? consult.ref_type.ref_type: ''}
              </p>
              <p className='my-0'><span className='text-bold'>File Name:</span> {consult.file_name}</p>
              <p className='my-0'><span className='text-bold'>Revision:</span> {consult.revision_num}</p>
              <p className='my-0'><span className='text-bold'>Industry Type:</span></p>
              <p> {consult.industry_type ? consult.industry_type.industry_type : ''} - {consult.industry_subtype ? consult.industry_subtype.development_type: ''}</p>
              <p className='my-0'><span className='text-bold'>Permit Issued: </span>
              {
                consult.permitted ? "Yes" 
                : "No"
              }
              </p>
              <p className='my-0'><span className='text-bold'>Permit Issued: </span>
              {
                consult.submited ? "Yes" 
                : "No"
              }
              </p>
              </Col>
              <Col md>
              <p className='my-0'><span className='text-bold'>Legal Land Description:</span></p>
              <p>{consult.location_desc}</p>
              <p className='my-0'><span className='text-bold'>Proponent's Project Name:</span></p>
              <p>{consult.prj_name}</p>
              </Col>
            </Row>
            <Row className="justify-content-md-left text-left border border-1 border-dark mx-1">
              <h5>Project Description</h5>
              <div className='project-description' dangerouslySetInnerHTML={{__html: consult.description}}></div>
            </Row>
          </Col>
          <Col className='border border-3 border-dark p-2 m-2' lg>
            <h4>Permitting Authority Details</h4>
            <Row className="justify-content-md-left text-left">
              <Col md>
              <p className='my-0'><span className='text-bold'>Permitting Authority:</span></p>
              <p>{consult.authority ? consult.authority.authority_name : ''}</p>
              </Col>
              <Col md>
              <p className='my-0'><span className='text-bold'>Permitting Officer:</span></p>
              { consult.contact ? <p>{consult.contact.l_name}, {consult.contact.f_name}</p> : ''}
              
              <p className='my-0'><span className='text-bold'>Consult Clerk:</span></p>
              <p>{consult.primary_staff.last_name}, {consult.primary_staff.first_name}</p>
              </Col>
            </Row>
            <h4>Applicant/Proponent Details</h4>
            <Row className="">
              <Col md>
                <p className='my-0'><span className='text-bold'>Applicants Reference #</span></p>
                <p>{consult.org ? consult.org.name : ''}</p>
              </Col>
            </Row>
          </Col>
          <Col className='border border-3 border-dark p-2 m-2' lg>
            <h4>Key Dates</h4>
            <Row className="justify-content-md-left text-left">
              <Col md>
              <p className='my-0'><span className='text-bold'>Application Date:</span></p>
              <p>{consult.date_application}</p>
              <p className='my-0'><span className='text-bold'>Est. Project/Permit Start:</span></p>
              <p>{consult.est_start_date}</p>
              </Col>
              <Col md>
              <p className='my-0'><span className='text-bold'>Response Requested By:</span></p>
              <p>{consult.date_due}</p>
              <p className='my-0'><span className='text-bold'>Est. Project/Permit End:</span></p>
              <p>{consult.est_comp_date}</p>
              </Col>
            </Row>
            <h4 className='my-0'>Technical Details</h4>
            <h5 className='mt-0'>Disturbance (Ha.)</h5>
            <Row className="mx-auto">
              <Col md>
              <p className='my-0'><span className='text-bold'>Crown</span></p>
              <p>{consult.disturb_crown_ha}</p>
              </Col>
              <Col md>
              <p className='my-0'><span className='text-bold'>Private</span></p>
              <p>{consult.disturb_priv_ha}</p>
              </Col>
              <Col md>
              <p className='my-0'><span className='text-bold'>MOTI</span></p>
              <p>{consult.moti_area_ha}</p>
              </Col>
              <Col md>
              <p className='my-0'><span className='text-bold'>Total New Cut</span></p>
              <p>{consult.new_cut_ha}</p>
              </Col>
            </Row>
            <h5 className='mt-0'>Water (m^3)</h5>
            <Row className="justify-content-md-left text-left">
              <Col md>
              <p className='my-0'><span className='text-bold'>Daily Water</span></p>
              <p>{consult.water_m3_day}</p>
              </Col>
              <Col md>
              <p className='my-0'><span className='text-bold'>Annual Water</span></p>
              <p>{consult.water_m3_year}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mx-auto consult-detail-row'>
          <Col className='border border-3 border-dark p-2 m-2' lg>
            <h3>Documents</h3>
            {
              consult.primary_staff.username === user.username ?
              <DocumentUpload setNewDocs={setNewDocs} consult={consult} docDeleted={docDeleted} setSelectedDocId={setSelectedDocId} /> 
              : <div></div>
            }
            <Stack gap="2" style={{maxHeight: "200px", "overflow": "auto"}}>
            {
              consult.referral_doc.map((doc, idx) => (
                <ConsultationFile key={doc.document_id} doc={doc} docDeleted={docDeleted} setSelectedDocId={setSelectedDocId}/>
              ))
            }
            </Stack>
          </Col>
          <Col className='border border-3 border-dark p-2 m-2' lg>
            <LeafletMap geoJson={geoJson}>
              {
                geoJson.map((d, idx) => (
                  <LayersControl.Overlay key={idx} checked name={d.file_name}>
                  <GeoJsonLayer 
                    data={d} setBounds={false}
                  />
                  </LayersControl.Overlay>
                ))
              }
              <MapZoom geoJson={geoJson}></MapZoom>
              <MapZoom geoJson={zoomGeojson}></MapZoom>
            </LeafletMap>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default ConsultationDetails