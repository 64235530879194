import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AuthContext from '../../context/AuthContext';
import { API_URL } from '../../constants';

const SubmitConsultModel = ({showModal, consultId, setShowModal, ...props}) => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const history = useHistory();
    let {authTokens} = useContext(AuthContext);

    useEffect(() => {
        if(showModal) {
            setShow(true);
            setShowModal(true);
        } else {
            setShow(false);
            setShowModal(false);
        }
    }, [showModal])

    const submitConsult  = async() => {
        let fetchUrl = `${API_URL}/api/landscms/consults/${consultId}/?submit_consult=true&`;
        let response = await fetch(fetchUrl, {
            method: 'PUT',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(
                {"submitted": "true"}
            )
        })

        if(response.status === 200) {
            setMessage("Consultation was successfully submitted.");
            setInterval(() => {
                setShowModal(false);
                setMessage("");
                history.push(`/consultation/${consultId}/`);
            }, 1000 * 3);
        } else if (response.statusText === 'Unauthorized') {
            setMessage("You are not authorized to submit this consultation.");
        } else if (response.status === 403) {
            setMessage("This consultation has already been submitted");
            setInterval(() => {
                setShowModal(false);
                setMessage("");
            }, 1000 * 3);
        }  else {
            setMessage("Error submitting consultation.");
            setInterval(() => {
                setShowModal(false);
                setMessage("");
            }, 1000 * 3);
        }
    }

  return (
    <div>
        <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                Consultation Submission Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body> 
                {
                    message !== '' ? <p>{message}</p> 
                    : 
                    <div>    
                        <p>
                            Once this consultation is submitted you will no longer be able to
                            upload documents or edit any consultation details.
                            <br></br><b>Are you sure you want to continue?</b>
                        </p>  
                        <Button className='btn-sm mx-2' onClick={submitConsult} variant="success">Submit</Button>
                        <Button className='btn-sm mx-2' onClick={() => setShowModal(false)} variant="warning">Cancel</Button>
                    </div>      
                }
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default SubmitConsultModel