import React, { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import AuthContext from '../context/AuthContext';

const Header = () => {
    const {user, logoutUser} = useContext(AuthContext);
    
    return (
        <div>
            <Navbar  bg="dark" variant="dark" expand="xl" className="p-1">
                <Navbar.Brand href="#home">DRFN Consultation Portal</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav.Link href="/">Home</Nav.Link>
                    {user ? 
                        <Nav.Link href="/consultations">My Consultations</Nav.Link> 
                        : <div></div>
                    }
                    {user ? 
                        <Nav.Link href="/companies">Companies</Nav.Link> 
                        : <div></div>
                    }
                    {user ? 
                        <Nav.Link href="/contacts">Contacts</Nav.Link> 
                        : <div></div>
                    }
                <Navbar.Collapse className="justify-content-end">
                {user ? 
                    <Nav.Link href="/profile">My Profile</Nav.Link> 
                    : <div></div>
                }
                {user ? 
                    <Nav.Link href="#" onClick={logoutUser}>Logout</Nav.Link> 
                    : <Nav.Link href="/login">Login</Nav.Link> 
                }
                {user ? 
                    <Navbar.Text>
                        Signed in as: <a href="#">{user.username}</a>
                    </Navbar.Text> 
                    : <Nav.Link href="/register">Request Access</Nav.Link> 
                }
                </Navbar.Collapse>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Header