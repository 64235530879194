import React from 'react';
import { Button } from 'react-bootstrap';

const SaveDefaultValues = ({postDefaultvalues}) => {

  return (
    <div>
        <Button variant="secondary" className="my-3 mx-2" onClick={postDefaultvalues}>Save Entries as Defaults</Button>
    </div>
  )
}

export default SaveDefaultValues