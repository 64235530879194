import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { API_URL } from '../constants';

import ConsultationDetails from '../components/ConsultationDetails';

const ConsultationDetail = () => {
    const {referral_id} = useParams();
    const history = useHistory();
    const [consultDetail, setConsultDetail] = useState([]);
    const [editingState, setEditingState] = useState(false);
    const [editingTime, setEditingTime] = useState('')
    const [displayTime, setDisplayTime] = useState({})
    const [consult, setConsult] = useState([])

    let {authTokens, logoutUser} = useContext(AuthContext);

    useEffect(() => {
      getConsult();
    }, [])

    useEffect(() => {
      let interval = setInterval(() => {
        if (editingTime > 0 && editingState) {
          var t = editingTime - 1000;
          setEditingTime(t);
          setDisplayTime(parseTime(t))
        }
      }, 1000)
      return () => { clearInterval(interval); }
    }, [editingTime])

    useEffect(() => {
      const twoMinutes = 1000 * 60 * 2;
      let interval = setInterval(() => {
          if(editingState){
              getConsult(true);
          }
      }, twoMinutes)
      return () => { clearInterval(interval); }
    }, [editingState])

    var parseTime = (milliSec) => {
      var hh = Math.floor(milliSec / 1000 / 60 / 60);
      milliSec -= hh * 1000 * 60 * 60;
      var mm = Math.floor(milliSec / 1000 / 60);
      milliSec -= mm * 1000 * 60;
      var ss = Math.floor(milliSec / 1000);
      milliSec -= ss * 1000;
      var editingTimeObj = {"hh": hh, "mm": mm, "ss": ss, "sss": milliSec}
      return editingTimeObj;
    }

    let getConsult = async(checkEditing) => {
      let fetch_url = `${API_URL}/api/landscms/consults/${referral_id}`;
      if (!checkEditing) {
          fetch_url = `${API_URL}/api/landscms/consults/${referral_id}/?checkEditing=true&`;
      }
      let response = await fetch(fetch_url, {
          method: 'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization':'Bearer ' + String(authTokens.access)
          }
      })
      let data = await response.json();
      if(response.status === 200) {
        if (!checkEditing) {
          setConsultDetail(<ConsultationDetails consult={data} inEditing={data.editing_locked} enableEditing={false}></ConsultationDetails>);
          setConsult(data);
        }
        setEditingState(data.editing_locked);
        if (data.editing_locked) {
          let timeDiff = (1000 * 60 * 5) - (new Date() - new Date(data.editing_start));
          setEditingTime(timeDiff);
        }
      } else if (response.statusText === 'Unauthorized') {
          logoutUser();
          history.push("/login");
      } else {
          console.log(response);
      }
  }

  return (
    <div>
     {
      editingState ? <h4 style={{color: "red"}}>This consultation is being edited by {consult.editor.username} and is locked for {displayTime.hh}h {displayTime.mm}m {displayTime.ss}s</h4> : ''
     }
      {consultDetail}
    </div>
  )
}

export default ConsultationDetail