import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import FileUpload from '../FileUpload';

const DocumentUpload = ({setNewDocs, ...props}) => {
    const acceptedFileFormats = ['.shp', '.prj', '.sbx', '.sbn', '.dbf', '.shx', '.zip', '.doc', '.docx', '.eml', '.xlsx', '.xls', '.jpg', '.jpeg', '.png', '.pdf', '.kml', '.kmz'];
    const fileFormatsStr = acceptedFileFormats.join(',');
    const [fileDropped, setFileDropped] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [newDoc, setNewDoc] = useState([])
    const [blockDrop, setBlockDrop] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        uploadFiles(selectedFiles);
        setBlockDrop(false);
        e.target.reset();
    }

    const uploadFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            file["referral_id"] = props.consult.referral_id
            setUploadedFiles(
                uploadedFiles => [
                    ...uploadedFiles,
                    <FileUpload key={Math.random()} file={file} callback={setNewDocs} acceptedFileFormats={acceptedFileFormats} docDeleted={props.docDeleted} setSelectedDocId={props.setSelectedDocId}/>
                ]
            )
        }
        setSelectedFiles([])
    }

    const handleFileChange = (e) => {
        setBlockDrop(true);
        setSelectedFiles(e.target.files);
    }

    const fileDragOver = (e) => {
        e.preventDefault();
        if (!blockDrop){
            setFileDropped(true);
        }
    }

    const fileDrop = (e) => {
        e.preventDefault();
        if (!blockDrop){
            let files = []
            if (e.dataTransfer.items) {
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    if (e.dataTransfer.items[i].kind === 'file') {
                        const file = e.dataTransfer.items[i].getAsFile();
                        files.push(file);
                    }
                }
                uploadFiles(files);
            } else {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                  console.log('... file[' + i + '].name = ' + e.dataTransfer.files[i].name + ' can not be uploaded');
                }
            }
        }
    }

  return (
    <div>
        <div 
            onDrop={(event) => fileDrop(event)} 
            onDragOver={(e) => fileDragOver(e)}
            onDragLeave={() => setFileDropped(false)}
            className={!fileDropped && !blockDrop ? "file-drop-zone" : "file-drop-zone-active"}
        >
            <h5 className="text-secondary">Drag Files Here To Upload or</h5>
            {
                !fileDropped ?
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <input
                    type="file" name="f_path"
                    onChange={handleFileChange} 
                    accept={fileFormatsStr}
                    multiple
                    />
                    <br></br>
                    <Button variant="success" className="my-2 btn-sm" type="submit" disabled={submitting}>Upload</Button>
                </form> : <div></div>
            }
        </div>
        <div>
        {uploadedFiles.map((f, idx) => (
            f
        ))}
        </div>
    </div>
  )
}

export default DocumentUpload