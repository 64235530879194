import React, {useState, useEffect, useContext} from 'react';
import AuthContext from '../context/AuthContext';
import { API_URL } from '../constants';

const NotesPage = () => {
    let [notes, setNotes] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    let {user, authTokens, logoutUser} = useContext(AuthContext);

    useEffect(() => {
        getNotes();
    }, [])

    let getNotes = async() => {
        let response = await fetch(`${API_URL}/api/notes/`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })

        let data = await response.json();
        if(response.status === 200) {
            setIsLoaded(true);
            setNotes(data);
        } else if (response.statusText === 'Unauthorized') {
            setIsLoaded(true);
            logoutUser();
        }
        console.log("Getting Notes");
    }

    return (
        <div>
            <h3>Hi {user.username} - These are your notes:</h3>
            <ul>
                {notes.map(note => (
                    <li key={note.id} >{note.body}</li>
                ))}
            </ul>
        </div>
    )
}

export default NotesPage;