import React, {useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { Formik, Form } from 'formik';
import FormikSingleSelect from './formik/SingleSelect';
import { API_URL } from '../../constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

const DocumentType = ({doc, ...props}) => {
    let {authTokens, logoutUser} = useContext(AuthContext);
    let history = useHistory();
    const [editDocType, setEditDocType] = useState(false);
    const [docTypes, setDocTypes] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        getDocTypes()
    }, [])

    let updateType = (e) => {
        let docType = docTypes.find( ({doc_type_id}) => doc_type_id === parseInt(e.target.value));
        putDocType(docType);
        setSelected(docType);
    }

    let putDocType = async(docType) => {
        let fetch_url = `${API_URL}/api/landscms/consult-documents/${doc.document_id}/`;
        let response = await fetch(fetch_url, {
            method: 'PUT',
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(docType)
        })
        if(response.status === 200) {
            setEditDocType(false);
        } else if (response.statusText === 'Unauthorized') {
            logoutUser();
            history.push("/login");
        } else {
            console.log(response);
        }
      }

    let getDocTypes = async() => {
      let fetch_url = `${API_URL}/api/landscms/consult-doc-types/`;
      let response = await fetch(fetch_url, {
          method: 'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization':'Bearer ' + String(authTokens.access)
          }
      })
      let data = await response.json();
      if(response.status === 200) {
        setDocTypes(data);
      } else if (response.statusText === 'Unauthorized') {
          setDocTypes([])
      } else {
          console.log(response);
      }
    }

  return (
    <div>
        {
            !editDocType ?
            <div>
            {selected.length === 0 ? <span>{doc.doc_type.doc_type}-{doc.doc_type.doc_subtype}</span>
            : <span>{selected.doc_type}-{selected.doc_subtype}</span>
            }
            <FontAwesomeIcon 
            className="mx-1"
            style= {{ color: 'green'}}
            icon={faPencil}
            title="Select/Edit Document Type"
            onClick={() => setEditDocType(true)}
            /> 
            </div>
            : 
            <div>
                <Formik
                initialValues={{
                    doc_type: doc.doc_type.doc_type_id
                }}
                >
                    <Form>
                        <FormikSingleSelect name="doc_type" 
                        style={{marginY: "5px", "width": "90%"}}
                        className="mx-auto"
                        onChange={(e) => updateType(e)}
                        value={selected.length === 0 ? doc.doc_type.doc_type_id : selected.doc_type_id}
                        >
                        <option value="">Select Document Type</option>
                        {docTypes.map(d => ( 
                            <option key={d.doc_type_id} value={d.doc_type_id}>{d.doc_type}-{d.doc_subtype}</option>
                        ))}
                        </FormikSingleSelect>
                    </Form>
                </Formik>
            </div>
        }
    </div>
  )
}

export default DocumentType